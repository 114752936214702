import { apiWithProject } from './greenetworkAPI'
import { SCHEDULE_COMMAND_FUNCTION } from '@/constants/commands'

const END_POINT = '/communication/downlink'

const sendZoneSchedule = (gateway, zones, message, active, remove) =>
    apiWithProject.post(`${END_POINT}/${gateway}`, {
        targets: zones,
        class: 'Neurolink',
        targetType: 'zones',
        messages: [
            {
                function: SCHEDULE_COMMAND_FUNCTION,
                data: message,
                active,
                delete: remove,
            },
        ],
    })

const Commands = { sendZoneSchedule }

export default Commands
