<template>
    <v-main>
        <div>
            <Notification v-if="is_logged_user && projectExists" />
            <v-fade-transition mode="out-in">
                <router-view />
            </v-fade-transition>
            <BottomSheet />
        </div>
    </v-main>
</template>

<script>
import { mapState } from 'vuex'
import BottomSheet from '@/components/core/BottomSheet'
import Notification from '@/components/core/Notification'
export default {
    name: 'ContentView',
    components: { BottomSheet, Notification },
    computed: {
        ...mapState({
            is_logged_user: (state) => state.authentication.status.loggedIn,
            projectExists: (state) => state.project.nameEui !== null,
        }),
    },
}
</script>

<style></style>
