import { Project } from '@/api'
import { PROJECT_KEY } from '@/constants/localStorage'

const state = {
    id: null,
    name: null,
    nameEui: null,
    attributes: {},
}

const getters = {
    getProjectNameEui(state) {
        return state.nameEui
    },
    getProjectAttributes(state) {
        return state.attributes
    },
}

const actions = {
    async setProject({ commit }, project) {
        const projectData = await Project.get(project.name_eui)
        localStorage.setItem(PROJECT_KEY, JSON.stringify(projectData.data))

        commit('SET_PROJECT', projectData.data)
    },
    async unsetProject({ commit }) {
        localStorage.removeItem(PROJECT_KEY)

        commit('SET_PROJECT', {
            id: null,
            name: null,
            name_eui: null,
            attributes: {},
        })
    },
    async loadSavedProject({ commit }) {
        const projectData = JSON.parse(localStorage.getItem(PROJECT_KEY))
        if (projectData) commit('SET_PROJECT', projectData)
    },
    async updateAttributes({ commit }, { name, data }) {
        await Project.updateAttributes(name, data)
        commit('UPDATE_ATTRIBUTES', { name, data })
    },
}

const mutations = {
    SET_PROJECT(state, project) {
        const { _id, name, name_eui, attributes = {} } = project
        state.id = _id
        state.name = name
        state.nameEui = name_eui
        state.attributes = attributes
    },
    UPDATE_ATTRIBUTES(state, { name, data }) {
        state.attributes[name] = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
