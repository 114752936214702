<template>
    <div>
        <v-dialog v-model="open" persistent width="500px">
            <v-card>
                <v-card-title>Delete {{ type.toLowerCase() }} </v-card-title>
                <v-form
                    class="spacing-playground pa-6"
                    ref="form"
                    lazy-validation
                >
                    <div class="grey--text" align="center">
                        <p>
                            Are you sure you want to delete this
                            {{ type.toLowerCase() }}?
                        </p>
                    </div>
                    <v-btn
                        class="mr-4 mt-4"
                        style="position: relative; left: 260px"
                        color="red"
                        :loading="deleteLoader"
                        @click="deleteItem()"
                        >Delete</v-btn
                    >
                    <v-btn
                        class="mr-1 mt-4"
                        style="position: relative; left: 255px"
                        color="blue-grey"
                        @click="close"
                        >Cancel</v-btn
                    >
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="msgSuccess" width="500px">
            <v-card :color="saveErr ? 'red' : 'primary'" class="text-center">
                <v-card-title class="white--text">
                    {{ msgText }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        elevation="0"
                        :color="saveErr ? 'red' : 'primary'"
                        class="white--text"
                        @click="msgSuccess = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { db, storage } from '../plugins/firebase'

export default {
    name: 'DeviceRegister',
    props: {
        open: { type: Boolean, default: false },
        type: { type: String, default: '' },
        element: { type: String, default: '' },
    },
    computed: {
        ...mapState({
            gateways: (state) => state.gateways.gateways,
            database_gateways: (state) => state.nodes.database_gateways,
            user: (state) => state.user.data,
            layouts: (state) => state.nodes.layouts,
        }),
    },
    data() {
        return {
            valid: true,
            deleteLoader: false,
            match: false,
            error: false,
            msgSuccess: false,
            saveErr: false,
            msgText: '',
        }
    },
    methods: {
        ...mapActions({
            deleteSchedules: 'nodes/deleteSchedules',
            deleteLayout: 'nodes/deleteLayout',
            deleteRegLights: 'nodes/deleteRegLights',
            retrieve_data: 'nodes/retrieve_data',
        }),
        async deleteItem() {
            try {
                const SCHEDULE_TYPE = 'Schedule'
                const LAYOUT_TYPE = 'Layout'
                const DELETION_MESSAGE_SUCCESS = 'deleted successfully'
                const SUCCESS_MESSAGE = 'RECEIVED'
                let status
                this.saveErr = false
                this.deleteLoader = true
                if (this.type === SCHEDULE_TYPE) {
                    status = await this.deleteSchedules({
                        scheduleIdx: this.$parent.index,
                    })
                    if (status !== SUCCESS_MESSAGE)
                        throw new Error('Error: Schedule')
                    this.msgText = `Schedule ${DELETION_MESSAGE_SUCCESS}`
                } else if (this.type === LAYOUT_TYPE) {
                    const layoutToDelete = this.layouts.find(
                        (layout) => layout.name === this.element
                    )
                    status = await this.deleteLayout(layoutToDelete)
                    if (!status) throw new Error('Error: Layout')
                    this.msgText = `Layout ${DELETION_MESSAGE_SUCCESS}`
                } else {
                    status = await this.deleteRegLights(this.element)
                    if (!status) throw new Error('Error: Light register')
                    this.msgText = `Light register ${DELETION_MESSAGE_SUCCESS}`
                }
                this.close()
                this.deleteLoader = false
                this.msgSuccess = true
            } catch (error) {
                const DELETION_MESSAGE_ERROR = 'could not be deleted'
                this.saveErr = true
                this.deleteLoader = false
                this.msgText = `${error.message} ${DELETION_MESSAGE_ERROR}`
                this.msgSuccess = true
            }
        },
        close() {
            this.$emit('close')
        },
        async refresh() {
            await this.retrieve_data()
        },
    },
    created() {
        if (this.user != undefined) {
            db.collection(`usersGreenweb/${this.user.uid}/layouts`).onSnapshot(
                (res) => {
                    const changes = res.docChanges()
                    if (changes.length > 0) {
                        localStorage.removeItem('layouts')
                        this.refresh()
                    }
                }
            )
        }
    },
}
</script>

<style scoped>
.my-input input {
    text-transform: uppercase;
}
.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}
@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
