const initialState = () => {
    return {
        list: [],
    }
}

const state = initialState()

const getters = {
    getList(state) {
        return state.list
    },
}

const actions = {
    async fetchList({ commit }) {
        commit('SET_LIST', [])
    },
}

const mutations = {
    SET_LIST(state, list) {
        state.list = list
    },
    RESET(state) {
        Object.assign(state, initialState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
